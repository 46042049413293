/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import AppNavbar from './components/AppNavbar';
import AccountSideBar from './components/AccountSideBar';
import { UserProvider } from './contexts/UserContext';
import { StripeProvider } from './contexts/StripeContext';
import ls from 'local-storage';
import { apiConfig } from './environment';
import axios from 'axios';

function determineIsDashboard(location) {
  // assume all pages are full screen unless listed here
  const publicRoutes = [
    '/account/billing',
    '/reports',
    '/upgradeplan',
    '/meetings',
    '/scheduleQuestion',
    '/scheduleMessage',
    '/scheduleEvent',
    '/editQuestion',
    '/editEvent',
    '/editMessage',
    '/pastMessages',
    '/answers',
    '/account/manage',
  ];

  // find current path in above list
  let isDashboard = false;
  for (let i = 0; i < publicRoutes.length; i++) {
    if (location.pathname === publicRoutes[i]) {
      isDashboard = true;
      break;
    }
  }

  return isDashboard;
}

const App = (props) => {
  const [state, setState] = useState({});
  const [user, setUser] = useState();
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const [stripeSuccess, setStripeSuccess] = useState();
  const stripeValues = useMemo(
    () => ({ stripeSuccess, setStripeSuccess }),
    [stripeSuccess, setStripeSuccess]
  );
  let history = useHistory();
  let token = null;

  useEffect(() => {
    onRouteChanged();
  }, [props.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRouteChanged = () => {
    // determine if full page layout upon initial load
    let isDashboard = determineIsDashboard(props.location);
    if (!isDashboard) {
      document
        .querySelector('.page-body-wrapper')
        .classList.add('full-page-wrapper');
    } else {
      document
        .querySelector('.page-body-wrapper')
        .classList.remove('full-page-wrapper');
    }
    setState({
      isFullPageLayout: !isDashboard,
    });

    // get the token from the URL (new login) or local storage (already logged in)
    const params = new URLSearchParams(props.location.search);
    token = params.get('token') ? params.get('token') : ls.get('token');

    // if this is a dashboard page, check logged in status
    if (!token && isDashboard) history.push('/login');
  };

  // get user data from token
  useEffect(() => {
    const fetchData = () => {
      axios
        .post(
          apiConfig.baseUrl + '/token/exchange/',
          { token: token },
          {
            auth: apiConfig.auth,
          }
        )
        .then((response) => {
          let user = {
            name: response.data.first_name,
            org: response.data.organization_data.name,
            email: response.data.email,
            userId: response.data.slack_id,
            teamId: response.data.organization_data.slack_team_id,
            appName: response.data.organization_data.app_name,
            appIcon: response.data.organization_data.app_icon,
            tier: response.data.organization_data.tier,
            slack_user_list: response.data.organization_data.slack_user_list,
            slack_channel_list:
              response.data.organization_data.slack_channel_list,
            slack_channel_name_list:
              response.data.organization_data.slack_channel_name_list,
            zoom_connected: response.data.organization_data.zoom_connected,
            timezone_description_str:
              response.data.organization_data.timezone_description_str,
            timezone_offset_str:
              response.data.organization_data.timezone_offset_str,
            token: token,
          };
          ls.set('token', token);
          setUser(user);

          // load user list for channel/dm selection - TEAM and ENTERPRISE tiers only
          if (user.tier !== 'FREE') {
            axios
              .get(
                apiConfig.baseUrl +
                  '/organizations/' +
                  user.teamId +
                  '/allslackusers/',
                {
                  auth: apiConfig.auth,
                }
              )
              .then((response) => {
                ls.set('all_slack_users', response.data.slack_all_users);
              });
          }
        })
        .catch(() => {
          // we could not retrieve user data from token, so delete all local storage data and force login again
          ls.clear();
          let isDashboard = determineIsDashboard(props.location);
          if (isDashboard) history.push('/login');
        });
    };

    if (token) {
      // store token in local storage and get user data from API
      fetchData();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  let navbarComponent = !state.isFullPageLayout ? <AppNavbar /> : '';
  let sidebarComponent = !state.isFullPageLayout ? <AccountSideBar /> : '';

  return (
    <UserProvider value={providerValue}>
      <StripeProvider value={stripeValues}>
        <head>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
            rel="stylesheet"
          />
        </head>
        <div className="container-scroller">
          {navbarComponent}
          <div className="container-fluid page-body-wrapper">
            {sidebarComponent}
            <div className="main-panel">
              <div className="content-wrapper">
                <AppRoutes />
              </div>
            </div>
          </div>
        </div>
      </StripeProvider>
    </UserProvider>
  );
};

export default withRouter(App);
