/* eslint-disable */
import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from './templates/shared/Spinner';

/* METL */
const LandingPageMetl = lazy(() => import('./pages/LandingPage'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const Contact = lazy(() => import('./pages/Contact'));
const BillingPage = lazy(() => import('./pages/BillingPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const UpgradePage = lazy(() => import('./pages/UpgradePage'));
const KnowledgeBase = lazy(() => import('./pages/KnowledgeBase'));
const UpgradePageLoggedIn = lazy(() => import('./pages/UpgradePageLoggedIn'));
const MeetingsAndContent = lazy(() => import('./pages/MeetingsAndContent'));
const QuestionForm = lazy(() => import('./pages/ScheduleQuestionForm'));
const ScheduleMessage = lazy(() => import('./pages/ScheduleMessage'));
const ScheduleEventForm = lazy(() => import('./pages/ScheduleEventForm'));
const EditQuestionPage = lazy(() => import('./pages/EditQuestionPage'));
const EditEventPage = lazy(() => import('./pages/EditEventPage'));
const EditMessagePage = lazy(() => import('./pages/EditMessagePage'));
const PastMessages = lazy(() => import('./pages/PastMessages'));
const QuestionAnswers = lazy(() => import('./pages/QuestionAnswers'));
const Integrations = lazy(() => import('./pages/Integrations'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Welcome = lazy(() => import('./pages/Welcome'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* METL */}
          <Route
            exact
            path="/termsandconditions"
            component={TermsAndConditions}
          />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/account/billing" component={BillingPage} />
          <Route exact path="/reports" component={ReportsPage} />
          <Route exact path="/upgrade" component={UpgradePage} />
          <Route exact path="/upgradeplan" component={UpgradePageLoggedIn} />
          <Route exact path="/help" component={KnowledgeBase} />
          <Route exact path="/meetings" component={MeetingsAndContent} />
          <Route exact path="/scheduleQuestion" component={QuestionForm} />
          <Route exact path="/scheduleMessage" component={ScheduleMessage} />
          <Route exact path="/scheduleEvent" component={ScheduleEventForm} />
          <Route exact path="/editQuestion" component={EditQuestionPage} />
          <Route exact path="/editEvent" component={EditEventPage} />
          <Route exact path="/editMessage" component={EditMessagePage} />
          <Route exact path="/pastMessages" component={PastMessages} />
          <Route exact path="/answers" component={QuestionAnswers} />
          <Route exact path="/account/manage" component={Integrations} />
          <Route exact path="/welcome" component={Welcome} />
          <Route exact path="/" component={LandingPageMetl} />
          <Route path="*" component={NotFound} />{' '}
          {/* The * path catches all pathnames to display */}
          {/*<Redirect to="/landing-page" />*/}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
