/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import UserContext from '../contexts/UserContext';
import { Collapse } from 'react-bootstrap';

const AccountSideBar = (props) => {
  const [tierData, setTierData] = useState({});
  const { user } = useContext(UserContext);
  let history = useHistory();

  useEffect(() => {
    // if we have UserData context, set the tier data
    // note - wrapped in try/catch because code cannot determine whether "user" is null or not for some reason; so we
    //  wrap it in a try to catch it when user.tier fails.
    try {
      setTierData({
        tier: user.tier,
        userCount: user.slack_user_list.split(' ').length,
        channelCount: user.slack_channel_list.split(' ').length,
      });
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  useEffect(() => {
    const body = document.querySelector('body');

    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  });

  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path);
  };
  let userRatio = 0;
  let channelRatio = 0;
  let limit = 0;

  if (tierData.tier) {
    switch (tierData.tier) {
      case 'FREE':
        limit = 1;
        break;
      case 'TEAM':
        limit = 10;
        break;
    }
  }
  tierData.userCount
    ? (userRatio = (tierData.userCount / 25) * 100)
    : (userRatio = 0);
  tierData.channelCount
    ? (channelRatio = (tierData.channelCount / limit) * 100)
    : (channelRatio = 0);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <span className="workspace-title"> {user && user.org} </span>
        </li>
        {user && user['tier'] !== 'FREE' ? (
          <li
            className={
              isPathActive('/meetings') ? 'nav-item active' : 'nav-item'
            }
            data-testid={
              isPathActive('/meetings')
                ? 'testing-active-menu-item-meetings'
                : ''
            }
          >
            <Link className="nav-link" to="/meetings">
              <span className="menu-title">Meetings / Content</span>
              <i className="mdi mdi-calendar-text menu-icon"></i>
            </Link>
            <Collapse in={true}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={
                      isPathActive('/pastMessages')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to="/pastMessages"
                  >
                    Sent Messages & Answers
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        ) : null}
        <li
          className={
            isPathActive('/account/billing') || isPathActive('/account/manage')
              ? 'nav-item active'
              : 'nav-item'
          }
        >
          <Link className="nav-link" to="/account/manage">
            <span className="menu-title">
              <Trans>Account</Trans>
            </span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
          <Collapse in={isPathActive('/account')}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {' '}
                <Link
                  className={
                    isPathActive('/account/manage')
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                  to="/account/manage"
                >
                  Manage App
                </Link>
              </li>
              <li
                className="nav-item"
                data-testid={
                  isPathActive('/account/billing')
                    ? 'testing-active-menu-item-billing'
                    : ''
                }
              >
                {' '}
                <Link
                  className={
                    isPathActive('/account/billing')
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                  to="/account/billing"
                >
                  Billing
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        {/* Temporarily removing reports from sidebar */}
        {/* <li className={ isPathActive('/reports') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/reports">
              <span className="menu-title">Reports</span>
              <i className="mdi mdi-translate menu-icon"></i>
            </Link>
          </li> */}
      </ul>
      <StatsHeader>{tierData.tier}</StatsHeader>
      {user && user.tier !== 'ENTERPRISE' ? (
        <>
          <br />
          <StatsTitle>Channels</StatsTitle>{' '}
          <Stats data-testid={'testing-channel-count-' + tierData.channelCount}>
            {tierData.channelCount ? tierData.channelCount : 'Loading'}/{limit}
          </Stats>
          <ProgressBar
            variant="primary"
            now={channelRatio}
            style={{ margin: '10px' }}
          />
          <StatsTitle>Users</StatsTitle>{' '}
          <Stats data-testid={'testing-user-count-' + tierData.userCount}>
            {tierData.userCount ? tierData.userCount : 'Loading'}/25
          </Stats>
          <ProgressBar
            variant="primary"
            now={userRatio}
            style={{ margin: '10px' }}
          />
        </>
      ) : null}
      <br />
      <UpgradeButton
        onClick={() => {
          history.push('/upgradeplan');
        }}
        type="button"
        className="btn btn-primary btn-fw"
      >
        {user && user.tier === 'ENTERPRISE'
          ? 'Downgrade'
          : user && user.tier === 'TEAM'
          ? 'Change Plan'
          : 'Upgrade'}
      </UpgradeButton>
    </nav>
  );
};

const StatsHeader = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
`;

const UpgradeButton = styled.button`
  margin-left: 20%;
`;
const StatsTitle = styled.span`
  padding: 10px;
  font-size: 13px;
`;

const Stats = styled.span`
  text-align: right;
  font-size: 13px;
  color: grey;
`;

export default withRouter(AccountSideBar);
