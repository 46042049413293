const apiUrl = 'https://api.metlapp.com';
const testStripeKey = 'pk_test_SOwjvkbv16CkAfalfAqQDowQ009LxwrwD5';
const liveStripeKey = 'pk_live_6etj6mXxcwT8M8bsn9WnH7Hm00IR6tyLSA';

export const redirectUrl = apiUrl + '/slack/oauth_redirect/';
export const clientId = '1225991357683.1799538640151';
export const scutlUrl = 'https://scutl-prod.nn.r.appspot.com';
export const stripePublicKey = liveStripeKey;
export const apiConfig = {
  baseUrl: apiUrl,
  auth: {
    username: 'admin@metlapp.com',
    password: '8Bm2De35F6Z$V!2',
  },
};
