import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import ls from 'local-storage';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { apiConfig } from '../environment';

const AppNavbar = () => {
  const { user, setUser } = useContext(UserContext);
  let history = useHistory();

  const logout = () => {
    axios
      .delete(apiConfig.baseUrl + `/token/delete/${user.token}/`, {
        auth: apiConfig.auth,
      })
      .finally(() => {
        // we use finally instead of then because we want to logout whether the axios call was successful or not
        ls.clear();
        setUser(null);
        history.push('/login');
      });
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img
            src={require('../../assets/images/logo.png')}
            alt="logo"
            style={{ width: 'auto' }}
          />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img
            src={require('../../assets/images/logo-mini.png')}
            style={{ height: 'auto' }}
            alt="logo"
          />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button> */}
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight className="d-sm-block d-lg-none">
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img"></div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">
                    <Trans>{user ? user.name : null}</Trans>
                  </p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                {user && user['tier'] !== 'FREE' ? (
                  <Dropdown.Item
                    onClick={() => {
                      history.push('/meetings');
                    }}
                  >
                    Meetings / Content
                  </Dropdown.Item>
                ) : null}
                <Dropdown.Item
                  onClick={() => {
                    history.push('/account/billing');
                  }}
                >
                  Billing
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    history.push('/upgradeplan');
                  }}
                >
                  Upgrade
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="mdi mdi-logout mr-2 text-primary"></i>
                  <Trans>Signout</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown alignRight className="d-none d-lg-block">
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img"></div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">
                    <Trans>hello, {user ? user.name : null}!</Trans>
                  </p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="mdi mdi-logout mr-2 text-primary"></i>
                  <Trans>Signout</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default AppNavbar;
